/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { connectSocketIO } from "../../utils/socket";
import { useNavigate } from "react-router-dom";
import Layout from "../layout";
import "./landing.css";

const LandingPage = () => {
  const navigate = useNavigate();
  const [socketData, setSocketData] = useState(null);

  useEffect(() => {
    // Establish Socket.IO connection
    const ws = connectSocketIO();

    // Event listeners
    ws.on("connect", () => {
      console.log("Connected to Socket.IO server");
    });

    ws.on("message", (data) => {
      try {
        const wsData = JSON.parse(data);
        if (wsData) {
          setSocketData(wsData);
        }
        if (wsData?.gamesPlayed) {
          localStorage.setItem("totalPlayed", wsData.gamesPlayed);
        }
      } catch (e) {
        console.error("Failed to parse message data:", e);
      }
    });

    ws.on("disconnect", (reason) => {
      console.log("Socket.IO connection closed", reason);
    });

    ws.on("connect_error", (error) => {
      console.error("Socket.IO connection error", error);
    });

    // Cleanup function to close Socket.IO connection when component unmounts
    return () => {
      ws.disconnect();
    };
  }, []);

  useEffect(() => {
    if (socketData?.goToHomePage === true) {
      navigate("/home");
    }
  }, [socketData?.goToHomePage]);

  return (
    <Layout>
      <video
        src="video/DoohIntroVid.mp4"
        playsInline
        autoPlay
        controls={false}
        muted
        width="2173px"
        height="3726px"
        style={{
          width: "2173px",
          height: "3726px",
          objectFit: "cover",
          transform: "scaleX(1.033)",
        }}
        loop
      />
    </Layout>
  );
};

export default LandingPage;
