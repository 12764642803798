export function formatTime(seconds) {
  // Calculate minutes and seconds
  const minutes = Math.floor(seconds / 60);
  const remainingSeconds = seconds % 60;

  // Format minutes and seconds to always be two digits
  const formattedMinutes = minutes.toString().padStart(2, "0");
  const formattedSeconds = remainingSeconds.toString().padStart(2, "0");

  // Return the formatted time
  return `${formattedMinutes}:${formattedSeconds}`;
}

export const handleGTM = (label) => {
  const originName = window?.location?.hostname?.split(".")[0] ?? "";
  const eventLabel = originName
    ? originName?.toUpperCase() + "_" + label
    : label;
  window.gtag("event", eventLabel);
};
