import React from "react";
import PropTypes from "prop-types";
import { handleGTM } from "../utils/helper";

const CtaButton = ({ href, imageUrl, width, height, tagLabel }) => {
  return (
    <a
      onClick={() => handleGTM(tagLabel)}
      href={href}
      target="_blank"
      rel="noopener noreferrer"
      id="buy-now"
    >
      <img
        src={imageUrl}
        alt="breeze shop"
        width={width ?? 50}
        height={height ?? 50}
        style={{
          objectFit: "contain",
        }}
      />
    </a>
  );
};

CtaButton.propTypes = {
  href: PropTypes.string.isRequired,
  imageUrl: PropTypes.string.isRequired,
  width: PropTypes.string,
  height: PropTypes.string,
  tagLabel: PropTypes.string.isRequired,
};

export default CtaButton;
