/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from "react";
import Layout from "../layout";
import { connectSocketIO } from "../../utils/socket";
import { useNavigate } from "react-router-dom";
import "./home.css";

let counter = 5;

const HomePage = () => {
  const navigate = useNavigate();
  const gameRef = useRef(null);
  const [timer, setTimer] = useState(27);
  const [step, setStep] = useState(1);
  const [socket, setSocket] = useState(null);
  const [socketData, setSocketData] = useState(null);
  const [counters, setCounters] = useState(5);

  const renderTimer = () => (
    <div className="timer-wrapper home-timer">
      <h2>{timer > 20 ? "20" : timer < 10 ? `0${timer}` : timer}</h2>
      <h4>TIME</h4>
    </div>
  );

  const addDirtyShirt = () => (
    <img
      src="gif/Dirty-shirt.gif"
      alt="dirty shirt"
      className="object-for-wash"
      style={{
        opacity: step === 2 ? "1" : "0",
        zIndex: step === 2 ? "4" : "1",
      }}
    />
  );

  const addLiquidDetergent = () => (
    <img
      src="gif/pour-liquid.gif"
      alt="liquid detergent"
      className="object-for-wash liquid-detergent-wrapper"
      style={{
        opacity: step === 3 ? "1" : "0",
        zIndex: step === 3 ? "4" : "1",
      }}
    />
  );

  useEffect(() => {
    const timeInterval = setInterval(() => {
      setTimer((oldTimer) => {
        if (oldTimer <= 1) {
          clearInterval(timeInterval);
          return 0;
        }
        return oldTimer - 1;
      });
    }, 1000);

    return () => {
      clearInterval(timeInterval);
      counter = 5;
    };
  }, [socketData?.startTurn]);

  useEffect(() => {
    let timeout;
    if (step === 4) {
      timeout = setTimeout(() => setStep(5), 600);
    } else if (step === 5) {
      timeout = setTimeout(() => setStep((old) => old + 1), 5000);
    } else if (step === 6) {
      timeout = setTimeout(() => {
        setStep((old) => old + 1);
        handleSendMessage("game-ended");
      }, 9000);
    }
    return () => clearTimeout(timeout);
  }, [step]);

  useEffect(() => {
    const ws = connectSocketIO();

    ws.on("connect", () => {
      console.log("Connected to Socket.IO server");
    });

    ws.on("message", (data) => {
      const wsData = JSON?.parse(data);
      if (data) {
        setSocketData(wsData);
      }
    });

    ws.on("disconnect", (reason) => {
      console.log("Socket.IO connection closed", reason);
    });

    ws.on("connect_error", (error) => {
      console.error("Socket.IO connection error", error);
    });
    setSocket(ws);

    return () => {
      ws.disconnect();
    };
  }, []);

  const handleSendMessage = (message) => {
    if (!socket) return;
    if (message === "time-out") {
      socket.emit("game-message", "timeOut");
      navigate("/");
      return;
    }
    if (message === "game-ended") {
      socket.emit("game-message", "game-ended");
      setTimeout(() => navigate("/"), 3000);
      return;
    }
    socket.emit("home-message", message);
  };

  useEffect(() => {
    const { gamePlaying, event } = socketData ?? {};
    if (!gamePlaying && event === "game-missed") {
      navigate("/");
    }
    if (gamePlaying) {
      if (event === "addCloth") {
        setStep(2);
        setTimeout(() => setStep(1), 2000);
      } else if (event === "addSoap") {
        setStep(3);
        setTimeout(() => setStep(1), 2000);
      } else if (event === "startWash") {
        setStep(4);
        const intervalId = setInterval(() => {
          counter--;
          setCounters(counter);
          if (counter < 0) {
            if (gameRef?.current) {
              gameRef?.current?.play();
            }
            clearInterval(intervalId);
          }
        }, 1000);
        setTimeout(() => setStep((old) => old + 1), 600);
      }
    }
  }, [socketData]);

  useEffect(() => {
    if (timer === 0 && step < 6) {
      handleSendMessage("time-out");
    }
  }, [timer, step]);

  return (
    <Layout>
      {(step < 6 || timer !== 0) && renderTimer()}

      <video
        src="video/openingEnd.mp4"
        preload="auto"
        playsInline
        autoPlay
        controls={false}
        muted
        style={{
          display: step > 5 ? "block" : "none",
          opacity: step > 5 ? "1" : "0",
          width: "100%",
          height: "3726px",
          objectFit: "cover",
          position: "fixed",
          zIndex: step > 5 ? "3" : "1",
          transition: "unset",
        }}
        width="1253px"
        height="2120px"
        ref={gameRef}
        className={step > 5 ? "openingEnd" : ""}
      />
      {step > 5 ? null : (
        <div
          className={`landing-content ${
            step === 14 ? "final-home-content" : "home-content"
          }`}
        >
          {(step === 4 || step === 5) && (
            <div className="washing-timer-home">
              <img src={`images/timer${counters}.png`} alt="timer" />
            </div>
          )}
          {step === 2 && addDirtyShirt()}
          {step === 3 && addLiquidDetergent()}
          <img
            className="machine-position-home"
            src="images/openLidMachine.png"
            alt="open Lid Machine"
            width={"40%"}
            style={{
              opacity: step < 4 ? "1" : "0",
              zIndex: step < 4 ? "3" : "1",
            }}
          />
          <img
            className="machine-position-home close-lid-machine"
            src="gif/Washing-machine-closing.gif"
            alt="lid closing animation"
            style={{
              display: step === 4 ? "block" : "none",
              zIndex: step === 4 ? "3" : "1",
            }}
            width={"100%"}
          />
          <img
            className="machine-position-home close-lid-position"
            src="images/closedMachine.png"
            alt="close Lid Machine"
            width={"46.3%"}
            style={{
              opacity: step === 4 || step === 5 ? "1" : "0",
              zIndex: step === 5 ? "3" : "1",
            }}
          />
        </div>
      )}
    </Layout>
  );
};

export default HomePage;
