import React from "react";
import { useLocation } from "react-router-dom";
import QRCode from "react-qr-code";
import useResponsiveScale from "../../utils/dynamicScreenScaling";
import "./layout.css";

const Layout = ({ children }) => {
  const location = useLocation();
  const { scaleValue, topValue } = useResponsiveScale();

  const interactUser = JSON.parse(localStorage.getItem("totalPlayed")) ?? 0;

  return (
    <div
      className="layout-wrapper"
      style={{
        transform: `translate(-50%, -${topValue}%) scale(${
          scaleValue / 100 + 0.007
        }, ${scaleValue / 100})`,
      }}
    >
      <div className="content">
        <div className="header">
          <img className="logo-img" src="images/logo.png" alt="breeze logo" />
        </div>

        {children}
      </div>
      <div className="footer">
        <div className="footer-content">
          <div className="user-details-wrapper">
            <p>USERS INTERACTING</p>
            <span className="user-id-wrapper">{interactUser}</span>
          </div>
          {location.pathname === "/" || location.pathname === "/home" ? (
            <div className="qr-wrapper">
              <span className="qr-sm-text">SCAN TO EXPERIENCE</span>
              <span className="qr-lg-text">THE POWER</span>
              <div className="qr-img-wrapper">
                <QRCode value={`${window?.location?.origin}/game`} />
              </div>
              <span className="bg-text">SCAN QR NOW</span>
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default Layout;
