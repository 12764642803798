import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import LandingPage from "./components/landingPage";
import HomePage from "./components/home";
import GamePage from "./components/game";
import ShopPage from "./components/shopNow";
import WaitingGamePage from "./components/game/waitingGamePage";
import TimeOutScreen from "./components/game/timeOutScreen";
import MissedChanceScreen from "./components/game/missedChancePage";
import "./App.css";


function App() {
  return (
    <div className="App">
      <Router>
        <Routes>
          <Route exact path="/" element={<LandingPage />} />
          <Route exact path="/home" element={<HomePage />} />
          <Route exact path="/game" element={<GamePage />} />
          <Route exact path="/shop-now" element={<ShopPage />} />
          <Route exact path="/waiting" element={<WaitingGamePage />} />
          <Route exact path="/timeout" element={<TimeOutScreen />} />
          <Route exact path="/missed" element={<MissedChanceScreen />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
